<div class="flex flex-col justify-center items-center mt-8">
  <div class="flex bg-primary justify-between w-full">
    <app-button name="Opnieuw beginnen" (action)="resetTemplate()"></app-button>
    <app-button name="Maak een PDF" (action)="downloadPdf()"></app-button>
  </div>

  <div class="flex justify-start w-full my-6 flex-col">
    <input type="text" [class]="nameError ? 'border-red-500' : ''" class="border-2 rounded border-gray-300 py-1 px-4 w-1/3 focus:outline-none focus:border-tone-blue" placeholder="Naam van PDF" [(ngModel)]="pdfName">
    <p *ngIf="nameError" class="text-red-500 mt-2">Er moet een naam worden ingevuld</p>
  </div>

  <div class="flex flex-wrap mx-4 items-center justify-center mt-4" id="content" #content>
    <div class="flex flex-wrap">
      <div *ngFor="let place of places1" id="drop-point-1" cdkDropList [cdkDropListData]="places[place]" (cdkDropListDropped)="drop($event)" class="h-28 w-28 border-2 m-1 transition duration-300 ease-in-out flex justify-center items-center" [class]="isDragging ? 'border-blue-300 shadow-lg' : 'border-black'">
        <img class="h-24 w-24 cursor-pointer isdragged" [src]="places[place][0]" alt="" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)" *ngIf="!checkIfKeyIsPresent(places[place][0])"/>
        <div class="h-24 w-24 flex justify-center items-center cursor-pointer isdragged" cdkDrag [cdkDragData]="places[place][0]" [class]="places[place][0].color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'" *ngIf="checkIfKeyIsPresent(places[place][0])">
          <span class="text-5xl -mt-3">{{ places[place][0].letter }}</span>
        </div>
      </div>
    </div>

    <div [class]="places2.length === 10 ? 'flex flex-wrap mt-4' : 'flex flex-wrap'">
      <div *ngFor="let place of places2" id="drop-point-2" cdkDropList [cdkDropListData]="places[place]" (cdkDropListDropped)="drop($event)" class="h-28 w-28 border-2 m-1 transition duration-300 ease-in-out flex justify-center items-center" [class]="isDragging ? 'border-blue-300 shadow-lg' : 'border-black'">
        <img class="h-24 w-24 cursor-pointer isdragged" [src]="places[place][0]" alt="" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)" *ngIf="!checkIfKeyIsPresent(places[place][0])"/>
        <div class="h-24 w-24 flex justify-center items-center cursor-pointer isdragged" cdkDrag  [cdkDragData]="places[place][0]" [class]="places[place][0].color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'" *ngIf="checkIfKeyIsPresent(places[place][0])">
          <span class="text-3xl -mt-3">{{ places[place][0].letter }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
