import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ToneInterface} from '@interfaces/tone-interface';
import {TonesQuery} from '@queries/tones.query';
import {animate, style, transition, trigger} from '@angular/animations';
import {ToneFilterPipe} from "../../pipes/tone-filter.pipe";
import {ToneSelectedFilterPipe} from "../../pipes/tone-selected-filter.pipe";
import {ToneColorFilterPipe} from "../../pipes/tone-color-filter.pipe";

@Component({
  selector: 'app-tones-selected',
  templateUrl: './tones-selected.component.html',
  styleUrls: ['./tones-selected.component.scss'],
  animations: [
    trigger(
      'notesAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('200ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('200ms', style({opacity: 0}))
        ])
      ]
    ),
  ]
})
export class TonesSelectedComponent implements OnInit {
  @Input() showByTone = false;
  @Output() dropEmit = new EventEmitter();
  @Output() dragging = new EventEmitter();
  @Input() selectSeeLetters = false;

  public tones$ = this.tonesQuery.tones$ as Observable<ToneInterface[]>;

  public selectedSymbols = true;
  public letters = false;
  public lip = false;
  public tongue = false;
  public lipAndTongue = false;
  public backOfThroat = false;
  public noSound = false;
  public withSound = false;

  public selectedSymbolsArray = [];
  public lettersArray = [];
  public lipArray = [];
  public tongueArray = [];
  public lipAndTongueArray = [];
  public backOfThroatArray = [];
  public noSoundArray = [];
  public withSoundArray = [];

  constructor(
    private tonesQuery: TonesQuery,
    private toneFilterPipe: ToneFilterPipe,
    private toneSelectedFilterPipe: ToneSelectedFilterPipe,
    private toneColorFilterPipe: ToneColorFilterPipe
  ) { }

  ngOnInit(): void {
    this.tonesQuery.addTones();

    this.tones$.subscribe((tones) => {
      const selectedSymbols = this.toneSelectedFilterPipe.transform(tones, true);
      this.lettersArray = this.toneSelectedFilterPipe.transform(tones, true).map((tone) => {
        return {
          letter: tone.letter,
          color: tone.color
        };
      });

      this.selectedSymbolsArray = selectedSymbols.map((note) => note.image);
      this.lipArray = this.toneFilterPipe.transform(selectedSymbols, 'lip').map((note) => note.image);
      this.tongueArray = this.toneFilterPipe.transform(selectedSymbols, 'tongue').map((note) => note.image);
      this.lipAndTongueArray = this.toneFilterPipe.transform(selectedSymbols, 'lipAndTongue').map((note) => note.image);
      this.backOfThroatArray = this.toneFilterPipe.transform(selectedSymbols, 'backOfThroat').map((note) => note.image);
      this.noSoundArray = this.toneColorFilterPipe.transform(selectedSymbols, 'yellow').map((note) => note.image);
      this.withSoundArray = this.toneColorFilterPipe.transform(selectedSymbols, 'blue').map((note) => note.image);
    });
  }

  dropEvent(event): void {
    this.dropEmit.emit(event);
  }

  checkDragging(checkDrag) {
    this.dragging.emit(checkDrag);
  }
}
