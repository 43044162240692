import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ToneInterface} from '@interfaces/tone-interface';
import {TonesQuery} from '@queries/tones.query';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-tones-selector',
  templateUrl: './tones-selector.component.html',
  styleUrls: ['./tones-selector.component.scss'],
  animations: [
    trigger(
      'notesAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('200ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('200ms', style({opacity: 0}))
        ])
      ]
    ),
  ]
})
export class TonesSelectorComponent implements OnInit {
  @Input() showByTone = false;

  public tones$ = this.tonesQuery.tones$ as Observable<ToneInterface[]>;

  public letters = false;
  public lip = false;
  public tongue = false;
  public lipAndTongue = false;
  public backOfThroat = false;
  public noSound = false;
  public withSound = false;

  constructor(
    private tonesQuery: TonesQuery
  ) { }

  ngOnInit(): void {
    this.tonesQuery.addTones();
  }

  selectTone(toneId: number) {
    this.tonesQuery.toneSelect(toneId, true);
  }

  unselectTone(toneId: number) {
    this.tonesQuery.toneSelect(toneId, false);
  }
}
