import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridsQuery} from '@queries/grids.query';

@Component({
  selector: 'app-grid-selector',
  templateUrl: './grid-selector.component.html',
  styleUrls: ['./grid-selector.component.scss']
})
export class GridSelectorComponent implements OnInit {
  @Output() gridSelected = new EventEmitter<boolean>();
  @Input() descriptionType: string;

  public grids$ = this.gridsQuery.selectAll();

  constructor(
    private gridsQuery: GridsQuery,
  ) { }

  ngOnInit(): void {
  }

  selectGrid(id: number): void {
    this.gridSelected.emit(true);
    this.gridsQuery.selectGrid(id);
  }
}
