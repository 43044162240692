import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from '@components/button/button.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TemplateComponent} from '@components/templates/template.component';
import { GridComponent } from '@components/grid/grid.component';
import { GridSelectorComponent } from '@components/grid-selector/grid-selector.component';
import { TonesComponent } from './tones/tones.component';
import {PipesModule} from '../pipes/pipes.module';
import { TonesSelectorComponent } from '@components/tones-selector/tones-selector.component';
import { TonesSelectedComponent } from '@components/tones-selected/tones-selected.component';
import {FormsModule} from '@angular/forms';
import { TemplatesSelectorComponent } from './templates-selector/templates-selector.component';


@NgModule({
  declarations: [
    ButtonComponent,
    TemplateComponent,
    GridComponent,
    GridSelectorComponent,
    TonesComponent,
    TonesSelectorComponent,
    TonesSelectedComponent,
    TemplatesSelectorComponent,
  ],
    exports: [
        ButtonComponent,
        TemplateComponent,
        GridSelectorComponent,
        TonesComponent,
        GridComponent,
        TemplatesSelectorComponent
    ],
    imports: [
        CommonModule,
        DragDropModule,
        PipesModule,
        FormsModule
    ]
})
export class ComponentsModule { }
