import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TemplatesQuery} from '@queries/templates.query';
import {Observable} from 'rxjs';
import {TemplateInterface} from '@interfaces/template-interface';

@Component({
  selector: 'app-templates-selector',
  templateUrl: './templates-selector.component.html',
  styleUrls: ['./templates-selector.component.scss']
})
export class TemplatesSelectorComponent implements OnInit {
  @Output() templateSelect = new EventEmitter<boolean>();
  public templates$ = this.templatesQuery.selectAll() as Observable<TemplateInterface[]>;

  constructor(
    private templatesQuery: TemplatesQuery
  ) { }

  ngOnInit(): void {
  }

  selectGrid(id) {
    this.templatesQuery.selectTemplate(id);
    this.templateSelect.emit(true);
  }

}
