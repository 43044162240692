import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() name: string;
  @Input() classes: string;
  @Output() action: EventEmitter<any> = new EventEmitter();

  submit(): void {
    this.action.emit();
  }

}
