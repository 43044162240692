import { EntityStore, StoreConfig } from '@datorama/akita';
import {createTonesInitialState, TonesState} from '@states/tones.state';

@StoreConfig({ name: 'tones' })
export class TonesStore extends EntityStore<TonesState> {

  constructor() {
    super(createTonesInitialState());
  }

}

