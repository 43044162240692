import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {TonesState} from '@states/tones.state';
import {TonesStore} from '@stores/tones.store';
import {ToneInterface} from '@interfaces/tone-interface';

@Injectable({ providedIn: 'root' })
export class TonesQuery extends QueryEntity<TonesState> {
  public tones$ = this.selectAll();

  constructor(protected store: TonesStore) {
    super(store);
  }

  addTones() {
    this.store.add(tones);
  }

  public toneSelect(toneId: number, selected: boolean) {
    this.store.update(toneId, {
      selected
    });
  }

  public unselectAllTones() {
    this.store.update(null, {
      selected: false,
    });
  }
}

const tones: Array<ToneInterface> = [
  {
    id: 1,
    tone: 'lip',
    image: 'assets/images/tones/p.jpg',
    letter: 'p',
    color: 'yellow',
    selected: false,
  },
  {
    id: 2,
    tone: 'lip',
    image: 'assets/images/tones/b.jpg',
    letter: 'b',
    color: 'blue',
    selected: false,
  },
  {
    id: 3,
    tone: 'lip',
    image: 'assets/images/tones/m.jpg',
    letter: 'm',
    color: 'blue',
    selected: false,
  },
  {
    id: 4,
    tone: 'backOfThroat',
    image: 'assets/images/tones/g-ch.jpg',
    letter: 'g',
    color: 'yellow',
    selected: false,
  },
  {
    id: 5,
    tone: 'tongue',
    image: 'assets/images/tones/L.jpg',
    letter: 'L',
    color: 'blue',
    selected: false,
  },
  {
    id: 6,
    tone: 'tongue',
    image: 'assets/images/tones/n.jpg',
    letter: 'n',
    color: 'blue',
    selected: false,
  },
  {
    id: 7,
    tone: 'tongue',
    image: 'assets/images/tones/t.jpg',
    letter: 't',
    color: 'yellow',
    selected: false,
  },
  {
    id: 8,
    tone: 'tongue',
    image: 'assets/images/tones/d.jpg',
    letter: 'd',
    color: 'blue',
    selected: false,
  },
  {
    id: 9,
    tone: 'tongue',
    image: 'assets/images/tones/s.jpg',
    letter: 's',
    color: 'yellow',
    selected: false,
  },
  {
    id: 10,
    tone: 'tongue',
    image: 'assets/images/tones/z.jpg',
    letter: 'z',
    color: 'blue',
    selected: false,
  },
  {
    id: 11,
    tone: 'lipAndTongue',
    image: 'assets/images/tones/f.jpg',
    letter: 'f',
    color: 'yellow',
    selected: false,
  },
  {
    id: 12,
    tone: 'lipAndTongue',
    image: 'assets/images/tones/v.jpg',
    letter: 'v',
    color: 'blue',
    selected: false,
  },
  {
    id: 13,
    tone: 'lipAndTongue',
    image: 'assets/images/tones/w.jpg',
    letter: 'w',
    color: 'blue',
    selected: false,
  },
  {
    id: 14,
    tone: 'lipAndTongue',
    image: 'assets/images/tones/j.jpg',
    letter: 'j',
    color: 'blue',
    selected: false,
  },
  {
    id: 15,
    tone: 'backOfThroat',
    image: 'assets/images/tones/h.jpg',
    letter: 'h',
    color: 'yellow',
    selected: false,
  },
  {
    id: 16,
    tone: 'backOfThroat',
    image: 'assets/images/tones/k.jpg',
    letter: 'k',
    color: 'yellow',
    selected: false,
  },
  {
    id: 17,
    tone: 'backOfThroat',
    image: 'assets/images/tones/r.jpg',
    letter: 'r',
    color: 'blue',
    selected: false,
  },
  {
    id: 18,
    tone: 'backOfThroat',
    image: 'assets/images/tones/a.jpg',
    letter: 'a',
    color: 'blue',
    selected: false,
  },
  {
    id: 19,
    tone: 'backOfThroat',
    image: 'assets/images/tones/e.jpg',
    letter: 'e',
    color: 'blue',
    selected: false,
  },
  {
    id: 20,
    tone: 'backOfThroat',
    image: 'assets/images/tones/i.jpg',
    letter: 'i',
    color: 'blue',
    selected: false,
  },
  {
    id: 21,
    tone: 'backOfThroat',
    image: 'assets/images/tones/o.jpg',
    letter: 'o',
    color: 'blue',
    selected: false,
  },
  {
    id: 22,
    tone: 'backOfThroat',
    image: 'assets/images/tones/u.jpg',
    letter: 'u',
    color: 'blue',
    selected: false,
  },
  {
    id: 23,
    tone: 'composed',
    image: 'assets/images/tones/aa.jpg',
    letter: 'aa',
    color: 'blue',
    selected: false,
  },
  {
    id: 24,
    tone: 'composed',
    image: 'assets/images/tones/ee.jpg',
    letter: 'ee',
    color: 'blue',
    selected: false,
  },
  {
    id: 25,
    tone: 'composed',
    image: 'assets/images/tones/ie.jpg',
    letter: 'ie',
    color: 'blue',
    selected: false,
  },
  {
    id: 26,
    tone: 'composed',
    image: 'assets/images/tones/oo.jpg',
    letter: 'oo',
    color: 'blue',
    selected: false,
  },
  {
    id: 27,
    tone: 'composed',
    image: 'assets/images/tones/au-ou.jpg',
    letter: 'au',
    color: 'blue',
    selected: false,
  },
  {
    id: 30,
    tone: 'composed',
    image: 'assets/images/tones/eu.jpg',
    letter: 'eu',
    color: 'blue',
    selected: false,
  },
  {
    id: 31,
    tone: 'composed',
    image: 'assets/images/tones/ui.jpg',
    letter: 'ui',
    color: 'blue',
    selected: false,
  },
  {
    id: 32,
    tone: 'composed',
    image: 'assets/images/tones/oe uil.jpg',
    letter: 'oe',
    color: 'blue',
    selected: false,
  },
  {
    id: 33,
    tone: 'composed',
    image: 'assets/images/tones/ei-ij.jpg',
    letter: 'ei',
    color: 'blue',
    selected: false,
  },
  {
    id: 34,
    tone: 'composed',
    image: 'assets/images/tones/ei-ij.jpg',
    letter: 'ij',
    color: 'blue',
    selected: false,
  },
  {
    id: 35,
    tone: 'composed',
    image: 'assets/images/tones/au-ou.jpg',
    letter: 'ou',
    color: 'blue',
    selected: false,
  },
  {
    id: 36,
    tone: 'composed',
    image: 'assets/images/tones/uu.jpg',
    letter: 'uu',
    color: 'blue',
    selected: false,
  }
];
