import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./pages/app/app.module').then(m => m.AppModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
