<div class="flex flex-col" *ngIf="!showByTone" [@notesAnimation]>
  <div class="flex flex-col">
    <h2 class="text-xl font-bold text-gray-700 mx-4 mb-2">enkel</h2>
    <div class="w-full h-px bg-black"></div>
    <div class="flex flex-wrap">
      <div *ngFor="let tone of tones$ | async | toneFilter:'composed': true">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-200 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-200 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-4">
    <h2 class="text-xl font-bold text-gray-700 mx-4 mb-2">samengesteld</h2>
    <div class="w-full h-px bg-black"></div>
    <div class="flex flex-wrap">
      <div *ngFor="let tone of tones$ | async | toneFilter:'composed'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-200 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-200 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-col" *ngIf="showByTone" [@notesAnimation]>
  <div class="flex flex-col">
    <div class="bg-primary text-white p-4 cursor-pointer" (click)="letters = !letters"><h2 class="text-lg">Letters</h2></div>
    <div class="flex flex-wrap" *ngIf="letters" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-200 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-200 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="lip = !lip"><h2 class="text-lg">Lipklank</h2></div>
    <div class="flex flex-wrap transition" *ngIf="lip" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneFilter:'lip'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="tongue = !tongue"><h2 class="text-lg">Tongpuntklank</h2></div>
    <div class="flex flex-wrap" *ngIf="tongue" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneFilter:'tongue'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="lipAndTongue = !lipAndTongue"><h2 class="text-lg">Lip- en Tongpositie</h2></div>
    <div class="flex flex-wrap" *ngIf="lipAndTongue" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneFilter:'lipAndTongue'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="backOfThroat = !backOfThroat"><h2 class="text-lg">Achter in de keel</h2></div>
    <div class="flex flex-wrap" *ngIf="backOfThroat" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneFilter:'backOfThroat'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="noSound = !noSound"><h2 class="text-lg">Stemloos</h2></div>
    <div class="flex flex-wrap" *ngIf="noSound" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneColorFilter:'yellow'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="withSound = !withSound"><h2 class="text-lg">Stemhebbend</h2></div>
    <div class="flex flex-wrap" *ngIf="withSound" [@notesAnimation]>
      <div *ngFor="let tone of tones$ | async | toneColorFilter:'blue'">
        <div class="w-24 h-24 relative rotate-main">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2" (click)="tone.selected ? unselectTone(tone.id) : selectTone(tone.id)">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone.image" alt="" [class]="!tone.selected ? 'selected' : ''">
            <div class="h-24 w-24 absolute transition duration-100 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
