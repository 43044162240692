import { Pipe, PipeTransform } from '@angular/core';
import {ToneInterface} from '@interfaces/tone-interface';

@Pipe({
  name: 'toneSelectedFilter'
})
export class ToneSelectedFilterPipe implements PipeTransform {

  transform(tones: ToneInterface[], selected: boolean): ToneInterface[] {
    if (tones === null) {
      return [];
    }
    return tones.filter((tone) => tone.selected === selected);
  }

}
