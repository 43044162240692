import {EntityState} from '@datorama/akita';
import {TemplateInterface} from '@interfaces/template-interface';

export interface TemplatesState extends EntityState<TemplateInterface> {}

export function createTemplatesInitialState(): Partial<TemplatesState> {
  return {
    selectedId: null
  };
}
