<div class="flex flex-col" *ngIf="selectedSymbolsArray.length > 0">
  <div class="flex flex-col">
    <div class="bg-primary text-white p-4 cursor-pointer" (click)="selectedSymbols = !selectedSymbols"><h2 class="text-lg">Geselecteerde symbolen</h2></div>
    <div class="flex flex-wrap transition" *ngIf="selectedSymbols" cdkDropList [cdkDropListData]="selectedSymbolsArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of selectedSymbolsArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" >
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="letters = !letters" *ngIf="selectSeeLetters"><h2 class="text-lg">Letters</h2></div>
    <div class="flex flex-wrap" *ngIf="letters && selectSeeLetters" [@notesAnimation] cdkDropList [cdkDropListData]="lettersArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of lettersArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag [cdkDragData]="tone" (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer mt-px">
            <div class="h-24 w-24 absolute transition duration-200 ease-in-out rotate-back text-center flex justify-center items-center" [class]="tone.color === 'blue' ? 'bg-tone-blue' : 'bg-tone-yellow'"><span class="text-5xl -mt-3">{{ tone.letter }}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="lip = !lip"><h2 class="text-lg">Lipklank</h2></div>
    <div class="flex flex-wrap transition" *ngIf="lip" [@notesAnimation] cdkDropList [cdkDropListData]="lipArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of lipArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="tongue = !tongue"><h2 class="text-lg">Tongpuntklank</h2></div>
    <div class="flex flex-wrap" *ngIf="tongue" [@notesAnimation] cdkDropList [cdkDropListData]="tongueArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of tongueArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" [class]="!tone.selected ? 'selected' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="lipAndTongue = !lipAndTongue"><h2 class="text-lg">Lip- en Tongpositie</h2></div>
    <div class="flex flex-wrap" *ngIf="lipAndTongue" [@notesAnimation] cdkDropList [cdkDropListData]="lipAndTongueArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of lipAndTongueArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" [class]="!tone.selected ? 'selected' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="backOfThroat = !backOfThroat"><h2 class="text-lg">Achter in de keel</h2></div>
    <div class="flex flex-wrap" *ngIf="backOfThroat" [@notesAnimation] cdkDropList [cdkDropListData]="backOfThroatArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of backOfThroatArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" [class]="!tone.selected ? 'selected' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="noSound = !noSound"><h2 class="text-lg">Stemloos</h2></div>
    <div class="flex flex-wrap" *ngIf="noSound" [@notesAnimation] cdkDropList [cdkDropListData]="noSoundArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of noSoundArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" [class]="!tone.selected ? 'selected' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary text-white p-4 mt-px cursor-pointer" (click)="withSound = !withSound"><h2 class="text-lg">Stemhebbend</h2></div>
    <div class="flex flex-wrap" *ngIf="withSound" [@notesAnimation] cdkDropList [cdkDropListData]="withSoundArray" (cdkDropListDropped)="dropEvent($event)">
      <div *ngFor="let tone of withSoundArray">
        <div class="w-24 h-24 relative rotate-main" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
          <div class="w-24 h-24 rotate-container cursor-pointer m-2">
            <img class="bg-primary h-24 w-24 absolute transition duration-100 ease-in-out rotateY" [src]="tone" alt="" [class]="!tone.selected ? 'selected' : ''">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
