import { EntityStore, StoreConfig } from '@datorama/akita';
import {createGridsInitialState, GridsState} from '@states/grids.state';

@StoreConfig({ name: 'Grids' })
export class GridsStore extends EntityStore<GridsState> {

  constructor() {
    super(createGridsInitialState());
  }

}

