import { Injectable } from '@angular/core';
import {getEntityType, QueryEntity} from '@datorama/akita';
import {TemplatesState} from '@states/templates.state';
import {TemplatesStore} from '@stores/templates.store';
import {TemplateInterface} from '@interfaces/template-interface';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TemplatesQuery extends QueryEntity<TemplatesState> {
  public templates$ = this.select((state) => {
    const all = state.ids.map((id) => state.entities[id]);

    return all.filter((template) => template.id === state.selectedId);
  });

  constructor(protected store: TemplatesStore) {
    super(store);
  }

  addTemplates() {
    this.store.add(templates);
  }

  selectTemplate(id: number): void {
    this.store.update({
      selectedId: id
    });
  }
}

const templates: Array<TemplateInterface> = [
  {
    id: 1,
    width: '12',
    height: '12',
    name: 'rups',
    image: 'assets/images/templates/rups.png',
    // tslint:disable-next-line:max-line-length
    description: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    places: [
      {
        name: 'place1',
        left: 25.5,
        top: 50
      },
      {
        name: 'place2',
        left: 35.2,
        top: 30,
      }, {
        name: 'place3',
        left: 45,
        top: 24
      },
      {
        name: 'place4',
        left: 55,
        top: 26
      }, {
        name: 'place5',
        left: 64.5,
        top: 38
      }, {
        name: 'place6',
        left: 74.4,
        top: 50
      }
    ]
  },
  {
    id: 2,
    width: '16',
    height: '16',
    name: 'train',
    image: 'assets/images/templates/train.png',
    // tslint:disable-next-line:max-line-length
    description: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    places: [
      {
        name: 'place1',
        left: 25.5,
        top: 43,
      }, {
        name: 'place2',
        left: 37,
        top: 43,
      }, {
        name: 'place3',
        left: 48.8,
        top: 43
      }, {
        name: 'place4',
        left: 60.4,
        top: 43
      }, {
        name: 'place5',
        left: 72,
        top: 43
      }, {
        name: 'place6',
        left: 83.8,
        top: 43
      }
    ]
  },
  {
    id: 3,
    width: '16',
    height: '16',
    name: 'plane',
    image: 'assets/images/templates/plane.png',
    // tslint:disable-next-line:max-line-length
    description: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    places: [
      {
        name: 'place1',
        left: 32,
        top: 42.6
      }, {
        name: 'place2',
        left: 48,
        top: 38,
      }, {
        name: 'place3',
        left: 66,
        top: 41.5
      }, {
        name: 'place4',
        left: 79,
        top: 38.5
      }
    ]
  },
  {
    id: 4,
    width: '22',
    height: '22',
    name: 'truck',
    image: 'assets/images/templates/truck.png',
    // tslint:disable-next-line:max-line-length
    description: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    places: [
      {
        name: 'place1',
        left: 31,
        top: 24
      },
      {
        name: 'place2',
        left: 46.5,
        top: 24
      }, {
        name: 'place3',
        left: 62,
        top: 24
      }, {
        name: 'place4',
        left: 77.5,
        top: 24
      }
    ]
  }
];
