import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TemplatesStore} from '@stores/templates.store';
import {GridsStore} from '@stores/grids.store';
import {TonesStore} from '@stores/tones.store';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    TemplatesStore,
    GridsStore,
    TonesStore
  ]
})
export class StoresModule { }
