import { Pipe, PipeTransform } from '@angular/core';
import {ToneInterface} from '@interfaces/tone-interface';

@Pipe({
  name: 'toneColorFilter'
})
export class ToneColorFilterPipe implements PipeTransform {

  transform(tones: ToneInterface[], color: string): ToneInterface[] {
    if (tones === null) {
      return [];
    }
    return tones.filter((tone) => tone.color === color);
  }

}
