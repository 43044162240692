import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {GridsState} from '@states/grids.state';
import {GridsStore} from '@stores/grids.store';
import {GridInterface} from '@interfaces/grid-interface';

@Injectable({ providedIn: 'root' })
export class GridsQuery extends QueryEntity<GridsState> {
  public grid$ = this.select((state) => {
    const all = state.ids.map(id => state.entities[id]);

    return all.filter((grid) => grid.id === state.selectedId);
  });

  constructor(protected store: GridsStore) {
    super(store);
  }

  addGrids() {
    this.store.add(grids);
  }

  selectGrid(id: number) {
    this.store.update({
      selectedId: id
    });
  }

}

const grids: Array<GridInterface> = [
  {
    id: 1,
    amount: 6,
    image: 'assets/images/grids/grid_6.jpg',
    // tslint:disable-next-line:max-line-length
    descriptionStempel: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    // tslint:disable-next-line:max-line-length
    descriptionLetters: 'Selecteer een sjabloon en de symbolen van de klanken en letters die je wilt gebruiken. Geef je werkblad een naam en klik op ‘Werkblad maken’. Nu kun je zowel symbolen als letters op je werkblad slepen. Letter plaatsen kan op het digibord of tablet.'
  },
  {
    id: 2,
    amount: 8,
    image: 'assets/images/grids/grid_8.jpg',
    // tslint:disable-next-line:max-line-length
    descriptionStempel: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    // tslint:disable-next-line:max-line-length
    descriptionLetters: 'Selecteer een sjabloon en de symbolen van de klanken en letters die je wilt gebruiken. Geef je werkblad een naam en klik op ‘Werkblad maken’. Nu kun je zowel symbolen als letters op je werkblad slepen. Letter plaatsen kan op het digibord of tablet.'
  },
  {
    id: 3,
    amount: 10,
    image: 'assets/images/grids/grid_10.jpg',
    // tslint:disable-next-line:max-line-length
    descriptionStempel: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    // tslint:disable-next-line:max-line-length
    descriptionLetters: 'Selecteer een sjabloon en de symbolen van de klanken en letters die je wilt gebruiken. Geef je werkblad een naam en klik op ‘Werkblad maken’. Nu kun je zowel symbolen als letters op je werkblad slepen. Letter plaatsen kan op het digibord of tablet.'
  },
  {
    id: 4,
    amount: 20,
    image: 'assets/images/grids/grid_20.jpg',
    // tslint:disable-next-line:max-line-length
    descriptionStempel: 'Selecteer de klanksymbolen en het sjabloon dat je wilt gebruiken. Geeft het werkblad een naam en klik op ‘Werkblad maken’. Sleep vervolgens de symbolen op de juiste plaats.  Met de knop ‘Maak een pdf’ kun je het werkblad downloaden en printen.',
    // tslint:disable-next-line:max-line-length
    descriptionLetters: 'Selecteer een sjabloon en de symbolen van de klanken en letters die je wilt gebruiken. Geef je werkblad een naam en klik op ‘Werkblad maken’. Nu kun je zowel symbolen als letters op je werkblad slepen. Letter plaatsen kan op het digibord of tablet.'
  }
];
