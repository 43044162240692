import { Pipe, PipeTransform } from '@angular/core';
import {ToneInterface} from '@interfaces/tone-interface';

@Pipe({
  name: 'toneFilter'
})
export class ToneFilterPipe implements PipeTransform {

  transform(tones: ToneInterface[], toneFilter: string, filterByNotEqual: boolean = false): ToneInterface[] {
    if (tones === null) {
      return [];
    }

    if (filterByNotEqual) {
      return tones.filter((tone) => tone.tone !== toneFilter);
    } else {
      return tones.filter((tone) => tone.tone === toneFilter);
    }
  }

}
