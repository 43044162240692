<div class="flex flex-col justify-center items-center mt-8">
  <div class="flex bg-primary justify-between w-full">
    <app-button name="Opnieuw beginnen" (click)="resetTemplate()"></app-button>
    <app-button name="Maak een PDF" (click)="downloadPdf()"></app-button>
  </div>

  <div class="flex flex-col justify-start w-full my-6">
    <input type="text" [class]="nameError ? 'border-red-500' : ''" class="border-2 rounded border-gray-300 py-1 px-4 w-1/3 focus:outline-none focus:border-tone-blue" placeholder="Naam van PDF" [(ngModel)]="pdfName">
    <p *ngIf="nameError" class="text-red-500 mt-2">Er moet een naam worden ingevuld</p>
  </div>

  <div class="relative" *ngFor="let template of template$ | async" #content>
    <img class="h-auto w-11/12 mx-auto" [src]="template.image" alt="">
    <div *ngFor="let place of template.places" id="drop-point" class="absolute border-2 border-transparent transition duration-300 ease-in-out" cdkDropList [cdkDropListData]="places[place.name]" (cdkDropListDropped)="drop($event)" [class]="isDragging ? 'border-blue-300 shadow-lg' + ' w-' + template.width + ' h-' + template.height : '' + ' w-' + template.width + ' h-' + template.height" [ngStyle]="{'left': place.left + '%', 'top': place.top + '%' }">
      <img class="cursor-pointer isdragged" [class]="'w-' + template.width + ' h-' + template.height" [src]="places[place.name][0]" alt="" cdkDrag (cdkDragStarted)="checkDragging(true)" (cdkDragEnded)="checkDragging(false)">
    </div>
  </div>
</div>
