<div class="flex mb-4" [class]="selectTones ? 'justify-around' : 'justify-end'">
  <app-button name="Kenmerk" classes="text-sm" (action)="byTone = true" *ngIf="selectTones"></app-button>
  <app-button name="Alle" classes="text-sm" (action)="byTone = false" *ngIf="selectTones"></app-button>
  <app-button name="Werkblad maken >>" classes="text-sm" (action)="selectTones = false" *ngIf="selectTones"></app-button>
  <app-button name="Klanksymbolen selecteren >>" (action)="selectTones = true" *ngIf="!selectTones && !seeLetters"></app-button>
  <app-button name="Klanken/letters selecteren >>" (action)="selectTones = true" *ngIf="!selectTones && seeLetters"></app-button>
</div>

<app-tones-selector [showByTone]="byTone" *ngIf="selectTones"></app-tones-selector>
<app-tones-selected *ngIf="!selectTones" [selectSeeLetters]="seeLetters" (dropEmit)="dropEvent($event)" (dragging)="checkDragging($event)"></app-tones-selected>

