import { Component, OnInit } from '@angular/core';
import {Route, Router} from '@angular/router';
import { Location } from '@angular/common';
import {TonesQuery} from '@queries/tones.query';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public router: Router,
    private location: Location,
    private tonesQuery: TonesQuery
  ) {}

  ngOnInit(): void {
  }

  goBack() {
    this.location.back();
    this.tonesQuery.unselectAllTones();
  }

}
