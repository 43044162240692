import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {createTemplatesInitialState, TemplatesState} from '@states/templates.state';

@StoreConfig({ name: 'Templates' })
export class TemplatesStore extends EntityStore<TemplatesState> {

  constructor() {
    super(createTemplatesInitialState());
  }
}

