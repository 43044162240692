import {EntityState} from '@datorama/akita';
import {GridInterface} from '@interfaces/grid-interface';

export interface GridsState extends EntityState<GridInterface> {}

export function createGridsInitialState(): Partial<GridsState> {
  return {
    selectedId: null
  };
}
