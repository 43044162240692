<div class="w-full h-16">
  <div class="px-16 py-4 flex justify-between">
    <div>
      <app-button *ngIf="router.url !== '/app/home'" name="Terug" (action)="goBack()"></app-button>
    </div>
    <img src="assets/images/logo.png" alt="" class="h-auto w-56"/>
  </div>
</div>
<div class="w-full bg-primary h-32 px-16 py-6">
  <h2 class="text-3xl text-white font-bold">Werkbladenmaker</h2>
  <p class="text-white">Maak je eigen werkbladen voor Programma Fonemisch Bewustzijn</p>
</div>
