import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ToneFilterPipe} from './tone-filter.pipe';
import { ToneColorFilterPipe } from './tone-color-filter.pipe';
import { ToneSelectedFilterPipe } from './tone-selected-filter.pipe';



@NgModule({
  declarations: [
    ToneFilterPipe,
    ToneColorFilterPipe,
    ToneSelectedFilterPipe
  ],
  exports: [
    ToneFilterPipe,
    ToneColorFilterPipe,
    ToneSelectedFilterPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ToneSelectedFilterPipe,
    ToneFilterPipe,
    ToneColorFilterPipe
  ]
})
export class PipesModule { }
