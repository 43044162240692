import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TonesQuery} from '@queries/tones.query';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-tones',
  templateUrl: './tones.component.html',
  styleUrls: ['./tones.component.scss'],
  animations: [
    trigger(
      'notesAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('200ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('200ms', style({opacity: 0}))
        ])
      ]
    ),
  ]
})
export class TonesComponent implements OnInit {
  @Output() dropSymbol = new EventEmitter();
  @Output() isDragging = new EventEmitter();
  @Input() seeLetters = false;

  public selectTones = false;
  public byTone = false;

  constructor(
    private tonesQuery: TonesQuery
  ) { }

  ngOnInit(): void {
    this.tonesQuery.addTones();
  }

  dropEvent(event): void {
    this.dropSymbol.emit(event);
  }

  checkDragging(checkDrag) {
    this.isDragging.emit(checkDrag);
  }
}
